import React, { useState } from 'react'
import TableMain from '../../components/Common/table/TableMain';
import { ApiHook } from '../../hooks/apiHook';
import { useTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SalesService } from '../../services/sales-report/salesReport';
import PayoutTableFilter from '../../components/Common/table/PayoutTableFilter';
// import { InvestmentService } from '../../services/investment/investment';


const SalesReport = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState("statement");
    const [currentPage, setCurrentPage] = useState(1);
    const userSelectedCurrency = useSelector(
        (state) => state.user?.selectedCurrency
    );



    const [selectStatement, setSelectedStatement] = useState(false);
    const [selectedTransfer, setSelectedTransfer] = useState(false);
    const [selectedPurchase, setSelectedPurchase] = useState(false);
    const [selectedEarnings, setSelectedEarnings] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
    const statement = ApiHook.CallEwalletStatement(
        currentPage,
        itemsPerPage,
        selectStatement
    );
    const transferHistory = ApiHook.CallTransferHistory(
        currentPage,
        itemsPerPage,
        selectedTransfer,
        setSelectedTransfer,
        selectedCategory,
        dateRange?.startDate,
        dateRange?.endDate
    );
    const purchaseHistory = ApiHook.CallPurchaseHistory(
        currentPage,
        itemsPerPage,
        selectedPurchase,
        setSelectedPurchase
    );
    const myEarnings = ApiHook.CallMyEarnings(
        currentPage,
        itemsPerPage,
        selectedEarnings,
        setSelectedEarnings,
        selectedCategory,
        dateRange?.startDate,
        dateRange?.endDate
    );
    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setCurrentPage(1);
        setApiTab(tab);
    };
    if (
        statement.isFetching ||
        transferHistory.isFetching ||
        purchaseHistory.isFetching ||
        myEarnings.isFetching
    ) {
        // return null;
    }
    const setApiTab = (tab) => {
        if (tab === "transfer_history") {
            setSelectedTransfer(true);
            setSelectedCategory([]);
        } else if (tab === "purchase_wallet") {
            setSelectedPurchase(true);
        } else if (tab === "my_earnings") {
            setSelectedEarnings(true);
            setSelectedCategory([]);
        } else {
            setSelectedStatement(true);
        }
    };
    const headers = [
        t("statement_date"),
        t("region"),
        t("brokerage"),
        t("policy_number"),
        t("agent_name"),
        t("agent_code"),
        t("principal_member"),
        t("description"),
        t("started"),
        t("commission_type"),
        t("method_of_payment"),
        t("age_at_entry"),
        t("policy_term"),
        t("premium"),
        t("amount"),
        t("retention"),
        t("vat"),
        t("payable"),
        t("retention_date"),
        t("cover"),
        t("assured_type"),
        t("payments"),
        t("captured")
    ];
    
    // const Investhistory = ApiHook.CallInvestmentHistory();
    const SalesReport = SalesService.CallSalesHistory();

    const dummyData = [

        {
            amount: '199.0000',
            createdAt: '2023-10-25T04:47:52.000Z',
            roi: '0.00'
        },
        {
            amount: '199.0000',
            createdAt: '2023-10-25T04:47:52.000Z',
            roi: '0.00'
        }
    ]

    return (
        <>
            <div className="page_head_top">{("Sales History")}</div>

            <div className="ewallet_table_section" style={{ marginTop: '20px' }}>
                <div className="table-responsive min-hieght-table">
                    <PayoutTableFilter
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        headers={headers}
                        data={SalesReport?.data?.data}
                        type={"salesReport"}
                    />
                    <TableMain
                        headers={headers}
                        data={SalesReport?.data?.data}
                        // data={dummyData}
                        startPage={1}
                        currentPage={statement?.data?.currentPage}
                        totalPages={statement?.data?.totalPages}
                        setCurrentPage={setCurrentPage}
                        type={"salesReport"}
                        itemsPerPage={itemsPerPage}
                        setItemsPerPage={setItemsPerPage}
                        activeTab={activeTab}
                        setApiTab={setApiTab}
                    />
                </div>
            </div>
        </>
    );
}

export default SalesReport