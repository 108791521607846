import { useMutation, useQuery } from "@tanstack/react-query";
import API from "../../api/api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { t } from "i18next";


const callApi = async (endpoint) => {
    try {
        const response = await API.get(endpoint);
        if (response.status === 200) {
            return response?.data;
        } else {
            return response?.data;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};
const PostApi = async (endpoint, data) => {
    try {
        const response = await API.post(endpoint, JSON.stringify(data));
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const SalesService = {
    CallSalesHistory: () => useQuery(["investment-history"], ()=>callApi(`sales-report`)),
    CallTourStatus: () => useMutation((data) => PostApi("tour-status", data)),

}