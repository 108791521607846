import React, { useEffect } from "react";
import QuickBalance from "../../components/Dashboard/DashboardTiles";
import JoiningChart from "../../components/Dashboard/JoiningChart";
import TeamMembers from "../../components/Dashboard/TeamMembers";
import TeamMembersEarningSection from "../../components/Dashboard/TeamPerformance";
import EarningsExpenses from "../../components/Dashboard/Earnings";
import { ApiHook } from "../../hooks/apiHook";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import RankComponent from "../../components/Dashboard/RankComponent";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { SalesService } from "../../services/sales-report/salesReport";

const Dashboard = () => {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user"));
  const joiningChartData = useSelector(
    (state) => state.dashboard?.dashboardOne
  );
  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  const moduleStatus = useSelector(
    (state) => state.dashboard?.appLayout?.moduleStatus
  );

  // --------------------------------------------- API -------------------------------------------------
  const dashboard = ApiHook.CallDashboardTiles();
  const dashboardDetails = ApiHook.CallDashboardDetails();
  const TourStatus = SalesService.CallTourStatus();


  if (dashboard.isFetching) {
    // return null;
  }

  // const driverObj = driver();
  // driverObj.highlight({
  //   element: "#join_chart",
  //   popover: {
  //     title: "Title",
  //     description: "Description"
  //   }
  // });
  const driverObj = driver({
    onNextClick:() => {
      console.log('Next Button Clicked');
      // Implement your own functionality here
      driverObj.moveNext();
    },
    onCloseClick:() => {
      console.log('Close Button Clicked');
      // Implement your own functionality here
      driverObj.destroy();

      FormData ={
        status: 1
      }

      TourStatus.mutateAsync(FormData);
    },
    onDestroyStarted: () => {
      console.log('Destroyed');
        driverObj.destroy();

        FormData ={
          status: 1
        }
  
        TourStatus.mutateAsync(FormData);
    },
    showProgress: true,
    steps: [
      { element: '#quick_bala_tour', popover: { title: 'Wallet Balance', description: 'Instantly view your financial status with a concise summary.' } },
      { element: '#join_chart_tour', popover: { title: 'Joinings', description: 'Ease into your team with our visual Joining Chart. Quickly connect with colleagues and encourage collaboration from day one.' } },
      { element: '#team_members_tour', popover: { title: 'Team Info & Earnings', description: `Get a comprehensive overview of your team's success and financial achievements.` } },
      { element: '.right_content_section', popover: { title: 'Profile', description: 'Quickly access essential information like your username, membership package, PVs, and payout details in one concise profile view.' } },
    ]
  });
  useEffect(()=>{
    if (joiningChartData?.tourData === 0) {
  
      const timeoutId = setTimeout(() => {
        driverObj.drive();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  },[joiningChartData])

  // driverObj.drive();

  

  return (
    <>
      <div className="page_head_top">{t("dashboard")}</div>
      <div className="center_content_head">
        <span>
          {t("welcome")} {user?.fullName}{" "}
        </span>
      </div>
     
      <QuickBalance
        tiles={dashboard?.data}
        currency={userSelectedCurrency}
        conversionFactor={conversionFactor}
      />
  
      <div className="joining_Teammbr_section" id="join_chart_tour">
        <div className="row">
          <JoiningChart charts={joiningChartData} />
          <TeamMembers members={dashboardDetails?.data?.newMembers} />
        </div>
      </div>
      <div className="team_members_earning_section" id="team_members_tour">
        <div className="row">
          <TeamMembersEarningSection
            topEarners={dashboardDetails?.data?.topEarners}
            currency={userSelectedCurrency}
            conversionFactor={conversionFactor}
          />
          {!!moduleStatus?.rank_status && (
            <RankComponent
              ranks={dashboardDetails?.data?.ranks}
              currentRank={dashboardDetails?.data?.currentRank}
            />
          )}
          <EarningsExpenses
            earnings={dashboardDetails?.data?.earnings}
            currency={userSelectedCurrency}
            conversionFactor={conversionFactor}
          />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
