import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import moment from "moment";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DOMPurify from 'dompurify';

const MailViewContent = ({ mailId, mailCheck, setMailCheck }) => {
  const { t } = useTranslation();
  const [replyBtn, setReplyBtn] = useState(false);
  const [replyMessage, setReplyMessage] = useState("");
  const [expandedItems, setExpandedItems] = useState([]);

  //----------------------------------------- API -------------------------------------------
  const singleMailDetails = ApiHook.CallSingleMailDetails(
    mailId,
    mailCheck,
    setMailCheck
  );
  const replyMutation = ApiHook.CallReplyMail();

  const modules = {
    toolbar: [
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline"],
      [{ align: [] }],
    ],
  };

  const toggleAccordionItem = (index) => {
    if (expandedItems.includes(index)) {
      setExpandedItems(expandedItems.filter((item) => item !== index));
    } else {
      setExpandedItems([...expandedItems, index]);
    }
  };

  const sendReply = () => {
    const lastIndex = singleMailDetails.data?.length - 1;
    const data = {
      parentMailId: singleMailDetails.data[lastIndex].id,
      subject: `Re:${singleMailDetails.data[lastIndex].subject}`,
      message: replyMessage,
    };
    replyMutation.mutate(data, {
      onSuccess: (res) => {
        if (res.status) {
          toast.success(res.data?.data);
          setMailCheck(true);
          setReplyMessage("");
        }else{
          toast.error(res.data?.description)
        }
      },
    });
  };

  useEffect(() => {
    const lastIndex = singleMailDetails.data?.length - 1;
    if (lastIndex >= 0) {
      setExpandedItems([...Array(lastIndex).keys()]); // Collapse all items except the last one
    }
  }, [singleMailDetails.data]);

  return (
    <>
      <div className="row">
        <div className="col-md-12 mb-4 mt-4">
          <div className="btn-toolbar">
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
                onClick={() => setReplyBtn(true)}
              >
                <i className="fa-solid fa-reply"></i> {t("reply")}
              </button>
            </div>
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
              >
                <i className="fa-regular fa-trash-can"></i> {t("delete")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {singleMailDetails.data && (
        <div className="message-body">
          <div className="accordion" id="accordionExample">
            {singleMailDetails.data?.map((item, index) => (
              <div className="accordion-item" key={index}>
                {console.log(item.message)}
                <h2 className="accordion-header" id={`heading${index}`}>
                  <button
                    className="accordion-button"
                    type="button"
                    aria-expanded={expandedItems.includes(index)}
                    onClick={() => toggleAccordionItem(index)}
                  >
                    <div className="sender-details">
                      <img
                        className="img-sm rounded-circle mr-3"
                        src="http://www.urbanui.com/dashflat/template/images/faces/face11.jpg"
                        alt=""
                      />
                      <div className="details">
                        <p className="msg-subject">
                          {t("subject")} : {item.subject}
                        </p>
                        <p className="sender-email">
                          {t("from")}: {item.fromUsername} (
                          <a className="maildtl" href="#">
                            {item.fromUserMail}
                          </a>
                          ) <br />
                          <span>
                            {t("date")}:{" "}
                            {moment(item.createdAt).format(
                              "ddd, MMM D, YYYY [at] h:mm A"
                            )}
                          </span>
                          <i className="mdi mdi-account-multiple-plus"></i>
                        </p>
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id={`collapse${index}`}
                  className={`accordion-collapse ${
                    expandedItems.includes(index) ? "collapse" : ""
                  } `}
                  aria-labelledby={`heading${index}`}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div className="message-content">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(item.message),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {replyBtn && (
            <div className="reply_message mt-4">
              <ReactQuill
                  value={replyMessage}
                  onChange={setReplyMessage}
                  modules={modules}
                  style={{height:'200px'}}
                />
              <button className="send_btn mt-5" onClick={() => sendReply()}>
                {t('send')}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MailViewContent;